import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveUpcomingContentOrderArgs } from '@coverright/data-access/types/content-management';

export function useSaveUpcomingContentOrder(options?: MutationHookOptions<any, MutationSaveUpcomingContentOrderArgs>) {
  return useMutation<any, MutationSaveUpcomingContentOrderArgs>(gql(saveVideoMutation), {
    ...options,
  })
}

const saveVideoMutation = `
mutation ($ids: [UUID!]!) {
  saveUpcomingContentOrder(ids: $ids)
}
`;
