import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveVideoCategoryArgs } from '@coverright/data-access/types/content-management';

export function useSaveVideoCategory(options?: MutationHookOptions<any, MutationSaveVideoCategoryArgs>) {
  return useMutation<any, MutationSaveVideoCategoryArgs>(gql(saveNoteMutation), {
    ...options,
  })
}

const saveNoteMutation = `
mutation ($category: VideoCategoryInput!) {
  saveVideoCategory(category: $category)
}
`;
