import React from "react";
import {
  Box, Button, Card,
  IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, Divider
} from "@mui/material";
import {useHistory} from "react-router";
import { RouteComponentProps } from "react-router-dom";
import paths from "../router-paths";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import {arrayMoveImmutable} from "array-move";
import * as _ from 'lodash';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ClearIcon from '@mui/icons-material/Clear';
import { Preloader } from '@coverright/ui/shared';
import AddContentButton from '../components/AddContentButton';
import { useSaveUpcomingContentOrder, useSaveVideoOrder, useVideoContent } from '@coverright/data-access/medicare';
import { UpcomingContentDto, Video } from '@coverright/data-access/types/content-management';

const columns = [
  { field: 'title', headerName: 'Title', width: 400 },
  { field: 'duration', headerName: 'Duration', width: 200},
];

const VideoList = (props: RouteComponentProps<{id: string}>) => {
  const {id} = props.match.params;
  const [getVideos, videoData] = useVideoContent();
  const [saveOrder] = useSaveVideoOrder();
  const [saveUpcomingOrder] = useSaveUpcomingContentOrder();
  const history = useHistory();
  const [rows, setRows] = React.useState<Video[]>([]);
  const [links, setLinks] = React.useState<UpcomingContentDto[]>([]);

  const [deleteRow, deleteData] = useMutation(gql(deleteRowMutation), {
    onCompleted: () => videoData.refetch(),
  });

  const [deleteUpcoming, deleteUpcomingData] = useMutation(gql(deleteUpcomingMutation), {
    onCompleted: () => videoData.refetch(),
  });

  React.useEffect(() => {
      if (id) {
        getVideos({variables: { categoryId: id }})
      }
  }, [id]);

  React.useEffect(() => {
    if (videoData?.data?.videoContent) {
      setRows(_.sortBy(videoData.data.videoContent.videos, 'sortingOrder'))
      setLinks(_.sortBy(videoData.data.videoContent.upcomingContents, 'sortingOrder'))
    }
  }, [videoData]);

  const onDrop = React.useCallback(({ removedIndex, addedIndex }: DropResult) => {
    const result = arrayMoveImmutable(rows, removedIndex as number, addedIndex as number)
    saveOrder({variables: {ids: result.map((i: any) => i.id)}});
    setRows(result);
  }, [rows]);

  const onLinkDrop = React.useCallback(({ removedIndex, addedIndex }: DropResult) => {
    const result = arrayMoveImmutable(links, removedIndex as number, addedIndex as number)
    saveUpcomingOrder({variables: {ids: result.map((i: any) => i.id)}});
    setLinks(result);
  }, [links]);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={false} />

      <Card>
        <Box display={'flex'} p={'30px'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Box display={'flex'} alignItems={'center'}>
            <Button onClick={() => history.push(paths.home)}>BACK</Button>
            <Typography color={'textPrimary'} className={'ml-15'} variant={'h3'}>{videoData?.data?.videoContent?.title}</Typography>
          </Box>
          <AddContentButton categoryId={id} />
        </Box>
        <Typography  className={'ml-15'} variant={'h4'}>Videos</Typography>
        <List>
          <Box>
            {rows.map(({ id, title }, i, items) => (
              <Box key={id}>
                <ListItem button onClick={() => history.push(paths.video + '/' + id)}>
                  <ListItemText primary={title} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        deleteRow({variables: {videoId: id}})
                      }}>
                        <ClearIcon style={{color: 'red'}}  />
                      </IconButton>
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
                {i < items.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </List>
        {!!links.length && <>
          <Typography  className={'ml-15 mt-20'} variant={'h4'}>Links</Typography>
          <List>
            <Box>
              {links.map(({ id, title }, i, items) => (
                <Box key={id}>
                  <ListItem button onClick={() => history.push(paths.item + '/' + id)}>
                    <ListItemText primary={title} />
                    <ListItemSecondaryAction>
                      <ListItemIcon className="drag-handle">
                        <DragHandleIcon />
                      </ListItemIcon>
                      <ListItemIcon>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          deleteUpcoming({variables: {id}})
                        }}>
                          <ClearIcon style={{color: 'red'}}  />
                        </IconButton>
                      </ListItemIcon>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {i < items.length - 1 && <Divider />}
                </Box>
              ))}
            </Box>
          </List>
        </>}
      </Card>
    </Box>
  </Box>;
}

export default VideoList;

const deleteRowMutation = `
  mutation ($videoId: UUID!) {
    deleteVideo(videoId: $videoId)
  }
`

const deleteUpcomingMutation = `
  mutation ($id: UUID!) {
    deleteUpcomingContent(id: $id)
  }
`
