import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { QueryVideoContentArgs, VideoCategoryWithVideos } from '@coverright/data-access/types/content-management';


export function useVideoContent() {
  return useLazyQuery<{videoContent: VideoCategoryWithVideos}, QueryVideoContentArgs>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
    }
  );
}

const request = `
query($categoryId: UUID!) {
  videoContent(categoryId: $categoryId) {
    id
    title
    sortingOrder
    upcomingContents {
      categoryId
      id
      link
      pictureUrl
      title
      label
      buttonLabel
      isLabelDate
      sortingOrder
    }
    videos {
      sortingOrder
      categoryId
      description
      duration
      id
      title
      url
    }
  }
}
`;
