import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { UpcomingContentDto } from '@coverright/data-access/types/content-management';


export function useUpcomingContents() {
  return useLazyQuery<{upcomingContents: UpcomingContentDto[]}>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
    }
  );
}

const request = `
query {
  upcomingContents {
    categoryId
    id
    link
    pictureUrl
    title
    label
    buttonLabel
    isLabelDate
    sortingOrder
  }
}
`;
