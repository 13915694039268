import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { environment } from '../../environments/environment';

const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const ImageUploader = ({onImageUploaded}: {onImageUploaded: (url: string) => void}) => {
  const [file, setFile] = useState<any>();
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
      if (files) {
        setFile(files[0]);
      }
    }
  });

  const onUploadClick = React.useCallback(() => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);

      fetch(
        environment.uploadThumbsUrl,
        {
          method: 'POST',
          body: formData,

          headers: {
          }
        }
      ).then((response) => response.json())
        .then(({path}) => {
          onImageUploaded(path)
          setFile(undefined)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [file])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => file && URL.revokeObjectURL(file?.preview);
  }, []);

  return (
    <div className="container">
      <Box sx={{display: 'flex', alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed #c9c9c9'}} {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <Typography className={'fs-14 medium pv-20 cursor-default'}>Drag 'n' drop image here, or click to select file</Typography>
      </Box>
      {file && <>
        <Typography className={'fs-16 medium mt-8'}>Preview</Typography>
        <Box sx={{display: 'flex', gap: 2, mt: 1}}>
          <div style={thumb} key={file?.name}>
            <div style={thumbInner}>
              <img
                src={file?.preview}
                style={img}
              />
            </div>
          </div>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button variant="contained" onClick={onUploadClick} endIcon={<UploadIcon />}>
              Upload
            </Button>
            <Typography sx={{color: 'red', ml: 2}}>Warning! Image will not be applied to video without uploading!</Typography>
          </Box>
        </Box>
      </>}
    </div>
  );
}

export default ImageUploader;
