import React from "react";
import {
  AppBar,
  Box,
  CssBaseline, Drawer,
  MenuItem, MenuList,
  Theme, Toolbar, Typography, useScrollTrigger
} from '@mui/material';
import {BrowserRouter, Link, Route, Switch, useHistory, useLocation} from "react-router-dom";
import paths from "./router-paths";
import VideoCategories from "./Video/VideoCategories";
import VideoList from "./Video/VideoList";
import {CreateVideoForm, UpdateVideoForm} from "./Video/VideoForm";
import CategoryForm from "./Video/CategoryForm";
import { withErrorBoundary } from '@coverright/ui/shared';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { createStyles, makeStyles } from '@mui/styles';
import Basics from "./pages/Basics";
import Facts from './pages/Facts';
import Podcasts from './pages/Podcasts';
import Webinars from './pages/Webinars';
import { CreateItemForm, UpdateItemForm } from './Video/ItemForm';
import { ElevationScroll } from '@coverright/utils';

const drawerWidth = 185;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '64px 0 200px',
      padding: '0 20px 0 200px',
      position: 'relative',
      width: '100%',
    },
    logoutButton: {
      color: 'white',
      marginLeft: 54,
      fontWeight: 700,
      cursor: 'pointer',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'white',
    },
  }),
);

//277821
function Layout() {
  const classes = useStyles();
  const {keycloak} = React.useContext(KeycloakContext);
  const history = useHistory();

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight: '48px'}}>
            <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant="h2" className={'white ml-16 bold'}>CoverRight</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <Typography onClick={() => keycloak.logout()} className={`white bold ${classes.logoutButton}`}>Logout</Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar style={{minHeight: '48px'}}/>
        <MenuList>
          <MenuItem onClick={() => history.push(paths.home)}>Medicare Basics</MenuItem>
          <MenuItem onClick={() => history.push(paths.facts)}>Fast Facts</MenuItem>
          <MenuItem onClick={() => history.push(paths.posdcasts)}>Podcast</MenuItem>
          <MenuItem onClick={() => history.push(paths.webinars)}>Live Webinars</MenuItem>
        </MenuList>
      </Drawer>
      <div className={classes.container}>
        <Box>
          <Switch>
            <Route exact path={paths.home} component={Basics}/>
            <Route exact path={paths.facts} component={Facts}/>
            <Route exact path={paths.posdcasts} component={Podcasts}/>
            <Route exact path={paths.webinars} component={Webinars}/>
            <Route exact path={paths.videos + '/:id'} component={VideoList}/>
            <Route exact path={paths.video + '/:id'} component={UpdateVideoForm}/>
            <Route exact path={paths.item + '/:id'} component={UpdateItemForm}/>
            <Route exact path={paths.video} component={CreateVideoForm}/>
            <Route exact path={paths.item} component={CreateItemForm}/>
            <Route exact path={paths.category + '/:id?'} component={CategoryForm}/>
          </Switch>
        </Box>
      </div>
    </>
  );
}

export default withErrorBoundary(Layout, true);
