import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import { SnackbarProvider } from 'notistack';
import {Preloader} from '@coverright/ui/shared';
import {AdminTheme} from '@coverright/ui/themes';
import React, { Suspense } from 'react';
import { ThemeProvider } from '@mui/material';
import { initTrackEvent } from '@coverright/shared/analytics';

initTrackEvent('partners-widget');

ReactDOM.render(
  <ThemeProvider theme={AdminTheme}>
    <SnackbarProvider maxSnack={3} preventDuplicate={true}
                      anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                      autoHideDuration={6000}>
      <Suspense fallback={<Preloader />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
