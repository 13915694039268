import React from "react";
import {
  Box, Divider,Card,
  IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
} from "@mui/material";
import paths from "../router-paths";
import {useHistory} from "react-router";
import {Container, Draggable, DropResult} from "react-smooth-dnd";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { VideoCategoryWithVideos } from '@coverright/data-access/types/content-management';

const VideoCategories = (props: {
  rows: VideoCategoryWithVideos[],
  onDrop: (res: DropResult) => void,
  deleteRow: (id: string) => void
}) => {
  const history = useHistory();

  return <List>
          <Box>
            {props.rows.map(({ id, title }, i, items) => (
              <Box>
                <ListItem button onClick={() => history.push(paths.videos + '/' + id)}>
                  <ListItemText primary={title} />
                  <ListItemSecondaryAction>
                    <ListItemIcon className="drag-handle">
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemIcon onClick={() => history.push(paths.category + '/' + id)}>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        props.deleteRow(id)
                      }}>
                        <ClearIcon style={{color: 'red'}}  />
                      </IconButton>
                    </ListItemIcon>
                  </ListItemSecondaryAction>
                </ListItem>
                {i < items.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </List>;
}

export default VideoCategories;

