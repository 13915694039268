import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveCategoryOrderArgs } from '@coverright/data-access/types/content-management';

export function useSaveCategoryOrder(options?: MutationHookOptions<any, MutationSaveCategoryOrderArgs>) {
  return useMutation<{saveCategoryOrder: string}, MutationSaveCategoryOrderArgs>(gql(saveVideoMutation), {
    ...options,
  })
}

const saveVideoMutation = `
mutation ($ids: [UUID!]!) {
  saveCategoryOrder(ids: $ids)
}
`;
