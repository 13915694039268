import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveUpcomingContentArgs } from '@coverright/data-access/types/content-management';

export function useSaveUpcomingContent(options?: MutationHookOptions<any, MutationSaveUpcomingContentArgs>) {
  return useMutation<any, MutationSaveUpcomingContentArgs>(gql(saveVideoMutation), {
    ...options,
  })
}

const saveVideoMutation = `
mutation ($upcomingContent: UpcomingContentDtoInput!) {
  saveUpcomingContent(upcomingContent: $upcomingContent)
}
`;
