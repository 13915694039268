import React, {useEffect} from "react";
import {useFormik} from "formik";
import { Button, Grid, MenuItem, Typography, Card, Box } from '@mui/material';
import * as yup from "yup";
import paths from "../router-paths";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { CRCheckbox, SelectInput, TextInput } from '@coverright/ui/inputs';
import { environment } from '../../environments/environment';
import ImageUploader from '../components/ImageUploader';
import { useSaveUpcomingContent, useUpcomingContents, useVideoCategories } from '@coverright/data-access/medicare';
import { UpcomingContentDto, UpcomingContentDtoInput } from '@coverright/data-access/types/content-management';

interface ItemFormProps {
  initial?: UpcomingContentDto,
  onSubmit: (data: UpcomingContentDtoInput) => void,
  onCancel: () => void,
}

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
  link: yup
    .string()
    .required('Url is required'),
  label: yup
    .string(),
  buttonLabel: yup
    .string(),
});

const ItemForm = (props: ItemFormProps) => {
  const {data, refetch, loading} = useVideoCategories();
  const [pictureUrl, setPictureUrl] = React.useState<string>();

  React.useEffect(() => {
    if (props.initial?.pictureUrl) {
      setPictureUrl(props.initial?.pictureUrl)
    }
  }, [props.initial?.pictureUrl])

  const onSubmit = React.useCallback((values: any) => {
    props.onSubmit({...values, pictureUrl})
  }, [pictureUrl])

  const formik = useFormik({
    initialValues: {
      label: props.initial?.label || '',
      buttonLabel: props.initial?.buttonLabel || '',
      title: props.initial?.title || '',
      link: props.initial?.link || '',
      categoryId: props.initial?.categoryId || '',
      id: props.initial?.id || '',
      isLabelDate: props.initial?.isLabelDate || false,
    },
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true
  });

  return <form onSubmit={formik.handleSubmit} className={'mt-15'}>
    <Grid container spacing={1} direction={'row'}>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="label"
          label="Label"
          value={formik.values.label}
          onChange={formik.handleChange}
          error={formik.touched.label && Boolean(formik.errors.label)}
          helperText={formik.touched.label && formik.errors.label}
        />
      </Grid>
      <Grid item xs={6}>
        <CRCheckbox variant={'standart'}
                    controlClassName={'mt-40'}
                    name={'isLabelDate'}
                    label={'Is Date?'}
                    checked={formik.values.isLabelDate}
                    onChange={e => formik.setFieldValue('isLabelDate', e.target.checked)} />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          name="link"
          label="Link"
          value={formik.values.link}
          onChange={formik.handleChange}
          error={formik.touched.link && Boolean(formik.errors.link)}
          helperText={formik.touched.link && formik.errors.link}
        />
      </Grid>
      <Grid item xs={6}>
        {props.initial?.categoryId && <SelectInput label={'Subcategory'}
                                                   value={formik.values.categoryId}
                                                   onChange={event => {
                                                     formik.setFieldValue("categoryId", event.target.value as string);
                                                   }}
                                                   error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                                                   helperText={formik.touched.categoryId ? formik.errors.categoryId as string : undefined}
        >
          {data?.videoCategories?.map(c => <MenuItem value={c.id}>{c.title}</MenuItem>)}
        </SelectInput>}
      </Grid>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          name="buttonLabel"
          label="CTA"
          value={formik.values.buttonLabel}
          onChange={formik.handleChange}
          error={formik.touched.buttonLabel && Boolean(formik.errors.buttonLabel)}
          helperText={formik.touched.buttonLabel && formik.errors.buttonLabel}
        />
      </Grid>
    </Grid>


    <div className={'h-20'} />
    <Typography className={'fs-18 medium mb-8'}>Thumbnail</Typography>
    {pictureUrl && <>
      <Box component={'img'}
           sx={{
             display: 'inline-flex',
             borderRadius: 2,
             border: '1px solid #eaeaea',
             marginBottom: 1,
             height: 100,
             boxSizing: 'border-box'
           }}
           src={environment.cdnUrl + pictureUrl} />
      <div className={'h-20'} />
    </>}
    <ImageUploader onImageUploaded={setPictureUrl} />
    <div className={'h-20'} />
    <Grid container sx={{alignItems: "center", justifyContent: 'space-between'}} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button disabled={!formik.isValid} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Grid>
    </Grid>
  </form>
}

export const CreateItemForm = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryId = urlParams.get('categoryId');

  const history = useHistory();
  const [saveVideo] = useSaveUpcomingContent();

  const onSubmitForm = React.useCallback((item: UpcomingContentDtoInput) => {
    saveVideo({variables: {upcomingContent: {...item, categoryId: categoryId, id: undefined}}}).then(() => {
      history.push(paths.videos + '/' + categoryId)
    })
  }, [categoryId]);

  return <Card sx={{p: 2}}>
    <Typography className={'fs-32 medium'}>Create link</Typography>
    <ItemForm onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + categoryId)} />
  </Card>
}

export const UpdateItemForm = (props: RouteComponentProps<{id: string}>) => {
  const {id} = props.match.params;

  const history = useHistory();
  const [saveVideo] = useSaveUpcomingContent();
  const [getVideo, data] = useUpcomingContents();

  const initial = React.useMemo(() => {
      if (data?.data?.upcomingContents) {
        return data?.data?.upcomingContents.find(c => c.id === id);
      }
      return undefined;
  }, [data]);

  const onSubmitForm = React.useCallback((item: UpcomingContentDtoInput) => {
    if (initial) {
      saveVideo({variables: {upcomingContent: {...item, id: initial.id}}}).then(() => {
        history.push(paths.videos + '/' + item.categoryId)
      })
    }
  }, [initial]);

  React.useEffect(() => {
    if (id) {
      getVideo({variables: {videoId: id}})
    }
  }, [id]);

  return initial ? <Card sx={{p: 2}}>
    <Typography className={'fs-32 medium'}>Update link</Typography>
    <ItemForm initial={initial} onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + initial.categoryId)} />
  </Card> : null
}
