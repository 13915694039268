import {useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { VideoCategoryWithVideos } from '@coverright/data-access/types/content-management';
import { contentAdminApolloClient } from '@coverright/data-access/apollo-clients';


export function useVideoCategories() {
  return useQuery<{videoCategories: VideoCategoryWithVideos[]}>(
    gql(request),
    {
      client: contentAdminApolloClient
    }
  );
}

const request = `
query {
  videoCategories {
    id
    title
    sortingOrder
    rowCount
    type
    videos {
      sortingOrder
      categoryId
      description
      duration
      id
      title
      pictureUrl
      url
    }
  }
}
`;
