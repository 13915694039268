import { Box, IconButton, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import paths from '../router-paths';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useHistory } from 'react-router';

const AddContentButton = ({categoryId}: {categoryId: string}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <>
    <IconButton component="span" onClick={handleClick}>
      <AddIcon />
    </IconButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuList>
        <MenuItem onClick={() => history.push(paths.video + '?categoryId=' + categoryId)}>Add youtube video</MenuItem>
        <MenuItem onClick={() => history.push(paths.item + '?categoryId=' + categoryId)}>Add external link</MenuItem>
      </MenuList>
    </Popover>
  </>
}

export default AddContentButton;
