import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import MainStepper, { NavStep } from './MainStepper';
import React from 'react';
import * as _ from 'lodash';
import { colors } from '@coverright/ui/themes';

type NavigationDashboardProps = {
  routeName: string,
  steps: NavStep[],
  sx?: SxProps,
  extra?: JSX.Element,
}

const Navigation = (props: NavigationDashboardProps) => {
  const [steps, setSteps] = React.useState<NavStep[]>([]);

  React.useEffect(() => {
    setSteps(props.steps)
  }, [props.steps]);

  React.useEffect(() => {
    setSteps(prev => prev.map(step => {
      let active = false;
      if (step.children?.length) {
        active = step.children.some(ch => ch.activeRoutes?.includes(props.routeName))
      }
      if (!active && step.activeRoutes) {
        active = step.activeRoutes?.includes(props.routeName)
      }
      return {
        ...step,
        active,
        children: step.children ? step.children.reduce((previousValue, currentValue, index, arr) => [
            ...previousValue,
            {
              ...currentValue,
              active: currentValue.forceActive || index <= arr.findIndex(s => s.activeRoutes?.includes(props.routeName)),
              onClick: (currentValue.forceActive || currentValue.forceOnClick || index <= arr.findIndex(s => s.activeRoutes?.includes(props.routeName))) ? currentValue.onClick : undefined,
            }
          ], [] as any) : step.children
      }
    }))
  }, [props.routeName, props.steps]);

  return (
    <Stack sx={{...props.sx}}>
      {steps.map((step, i) => <Line key={i} step={step} />)}
      {props.extra}
    </Stack>
  );
}

export const NavigationDashboard = React.memo(Navigation, areEqual);

function areEqual(prevProps: NavigationDashboardProps, nextProps: NavigationDashboardProps): boolean {
  return prevProps.routeName === nextProps.routeName && _.isEqual(prevProps.steps, nextProps.steps);
}

const Line = ({step}: {step: NavStep}) => {
  return <div>
    {!step.active && <Box className={'flex'} sx={{
      p: '10px 16px 12px 10px',
      cursor: step.onClick ? 'pointer' : 'default',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#EBF7F7'
      }
    }} onClick={step.onClick}>
      <Box mr={'8px'} className={'w-24 h-24'}><img src={step.icon + '.svg'} /></Box>
      <Typography sx={{mt: '3px'}} className={'fs-16 lh-20'}>{step.label}</Typography>
    </Box>}
    {step.active && <Box className={'flex'} sx={{
      p: '10px 16px 12px 10px',
      cursor: step.onClick ? 'pointer' : 'default',
      borderRadius: '10px',
      backgroundColor: colors.custom.green.variant2,
      '&:hover': {
        backgroundColor: colors.custom.green.variant2
      }
    }} onClick={step.onClick}>
      <Box mr={'8px'} className={'w-24 h-24'}><img src={step.icon + '-active.svg'} /></Box>
      <Typography sx={{mt: '3px', color: 'white'}} className={'fs-16 lh-20'}>{step.label}</Typography>
    </Box>}
    {/*step.active && <MainStepper step={step}/>*/}
  </div>
}
