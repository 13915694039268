const paths = {
  home: '/',
  videos: '/videos',
  video: '/video',
  item: '/item',
  facts: '/facts',
  webinars: '/webinars',
  posdcasts: '/posdcasts',
  category: '/category',
};

export default paths;
