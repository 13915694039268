import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import paths from "../router-paths";
import {RouteComponentProps, useHistory} from "react-router";
import {Button, Grid, Typography, Card, MenuItem} from "@mui/material";
import { SelectInput, TextInput } from '@coverright/ui/inputs';
import * as QueryString from 'query-string';
import { useSaveVideoCategory, useVideoCategories } from '@coverright/data-access/medicare';
import { VideoCategoryInput, VideoContentCategoryType } from '@coverright/data-access/types/content-management';


const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
  subTitle: yup
    .string(),
  rowCount: yup
    .number(),
  type: yup
    .string(),
});

const CategoryForm = (props: RouteComponentProps<{ id?: string, type?: string }>) => {
  const params = QueryString.parse(document.location.search);
  const {id} = props.match.params;
  const [save] = useSaveVideoCategory();
  const history = useHistory();
  const {data} = useVideoCategories();

  const initial = React.useMemo(() => {
    if (data?.videoCategories && id) {
      return data?.videoCategories.find(c => c.id === id);
    }
    return undefined;
  }, [data, id]);

  const onSaveClick = (values: VideoCategoryInput) => {
    save({
      variables: {
        category: {
          ...{...initial, videos: undefined},
          title: values.title,
          subTitle: values.subTitle,
          type: values.type,
          rowCount: values.rowCount || null
        }
      }
    }).then(() => {
      history.goBack()
    })
  }

  const formik = useFormik({
    initialValues: {
      title: initial?.title || '',
      subTitle: initial?.subTitle || '',
      rowCount: initial?.rowCount,
      type: initial?.type || params?.type || '' as any,
    },
    validationSchema: validationSchema,
    onSubmit: onSaveClick,
    enableReinitialize: true
  });

  React.useEffect(() => {
    if (initial) {
      formik.resetForm();
    }
  }, [initial]);

  return <Card sx={{p: 2}}>
      <Typography className={'fs-32 medium'}>Video category</Typography>
      <form onSubmit={formik.handleSubmit} className={'mt-15'}>
        <TextInput
          fullWidth
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <div className={'h-20'} />
        <TextInput
          fullWidth
          name="subTitle"
          label="Subtitle"
          value={formik.values.subTitle}
          onChange={formik.handleChange}
          error={formik.touched.subTitle && Boolean(formik.errors.subTitle)}
          helperText={formik.touched.subTitle && formik.errors.subTitle}
        />
        <div className={'h-20'} />
        <TextInput
          fullWidth
          name="rowCount"
          label="Rows count"
          value={formik.values.rowCount}
          onChange={formik.handleChange}
          error={formik.touched.rowCount && Boolean(formik.errors.rowCount)}
          helperText={formik.touched.rowCount && formik.errors.rowCount}
        />
        <div className={'h-20'} />
        <SelectInput label={'Master category'}
                     value={formik.values.type}
                     onChange={event => {
                       formik.setFieldValue("type", event.target.value as string);
                     }}
                     error={formik.touched.type && Boolean(formik.errors.type)}
                     helperText={formik.touched.type ? formik.errors.type as string : undefined}
        >
          <MenuItem value={VideoContentCategoryType.Basics}>Basics</MenuItem>
          <MenuItem value={VideoContentCategoryType.LiveWebinars}>Webinars</MenuItem>
          <MenuItem value={VideoContentCategoryType.MedicareFastFacts}>Medicare Fast Facts</MenuItem>
          <MenuItem value={VideoContentCategoryType.Podcast}>Podcasts</MenuItem>
        </SelectInput>
        <div className={'h-20'} />
        <Grid container sx={{alignItems: "center", justifyContent: 'space-between'}} direction={'row'}>
          <Grid item>
            <Button variant={'outlined'} onClick={() => history.push(paths.home)}>CANCEL</Button>
          </Grid>
          <Grid item>
            <Button disabled={!formik.isValid} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  ;
}

export default CategoryForm;
