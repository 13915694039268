import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { MutationDeleteVideoCategoryArgs } from '@coverright/data-access/types/content-management';


export function useDeleteVideoCategory(options: MutationHookOptions<{deleteVideoCategory: string}, MutationDeleteVideoCategoryArgs>) {
  return useMutation<{deleteVideoCategory: string}, MutationDeleteVideoCategoryArgs>(
    gql(deleteRowMutation),
    options
  );
}

const deleteRowMutation = `
  mutation ($categoryId: UUID!) {
    deleteVideoCategory(categoryId: $categoryId)
  }
`
