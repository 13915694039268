import React, {useEffect} from "react";
import {useFormik} from "formik";
import { Button, Grid, MenuItem, Typography, Card, Box } from '@mui/material';
import * as yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import paths from "../router-paths";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { SelectInput, TextInput } from '@coverright/ui/inputs';
import ImageUploader from "../components/ImageUploader";
import { environment } from '../../environments/environment';
import { useSaveVideo, useVideo, useVideoCategories } from '@coverright/data-access/medicare';
import { Video } from '@coverright/data-access/types/content-management';

interface VideoFormProps {
  initial?: Video,
  onSubmit: (data: Video) => void,
  onCancel: () => void,
}

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Title is required'),
  url: yup
    .string()
    .required('Url is required'),
  description: yup
    .string()
    .required('Description is required'),
  duration: yup
    .string()
    .required('Duration is required'),
});




const VideoForm = (props: VideoFormProps) => {
  const {data, refetch, loading} = useVideoCategories();
  const [pictureUrl, setPictureUrl] = React.useState<string>();

  React.useEffect(() => {
    if (props.initial?.pictureUrl) {
      setPictureUrl(props.initial?.pictureUrl)
    }
  }, [props.initial?.pictureUrl])

  const onSubmit = React.useCallback((values: any) => {
    props.onSubmit({...values, pictureUrl})
  }, [pictureUrl])

  const formik = useFormik({
    initialValues: {
      title: props.initial?.title || '',
      url: props.initial?.url || '',
      description: props.initial?.description || '',
      duration: props.initial?.duration || '',
      categoryId: props.initial?.categoryId || '',
      id: props.initial?.id || '',
    },
    validationSchema: validationSchema,
    onSubmit,
    enableReinitialize: true
  });

  return <form onSubmit={formik.handleSubmit} className={'mt-15'}>
    <Grid container spacing={1} direction={'row'}>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          name="title"
          label="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name="duration"
          label="Duration"
          value={formik.values.duration}
          onChange={formik.handleChange}
          error={formik.touched.duration && Boolean(formik.errors.duration)}
          helperText={formik.touched.duration && formik.errors.duration}
        />
      </Grid>
    </Grid>
    <TextInput
      fullWidth
      name="url"
      label="Url"
      value={formik.values.url}
      onChange={formik.handleChange}
      error={formik.touched.url && Boolean(formik.errors.url)}
      helperText={formik.touched.url && formik.errors.url}
    />
    {props.initial?.categoryId && <SelectInput label={'Subcategory'}
                value={formik.values.categoryId}
                onChange={event => {
                  formik.setFieldValue("categoryId", event.target.value as string);
                }}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                helperText={formik.touched.categoryId ? formik.errors.categoryId as string : undefined}
    >
      {data?.videoCategories?.map(c => <MenuItem value={c.id}>{c.title}</MenuItem>)}
    </SelectInput>}
    <Typography className={'fs-18 medium mb-8'}>Description</Typography>
    <CKEditor
      editor={ ClassicEditor }
      data={formik.values.description}
      onReady={ (editor: any) => {
        // You can store the "editor" and use when it is needed.
      } }
      onChange={ ( event: any, editor: any ) => {
        const data = editor.getData();
        formik.setFieldValue('description', data)
      } }
    />
    <div className={'h-20'} />
    <Typography className={'fs-18 medium mb-8'}>Thumbnail</Typography>
    {pictureUrl && <>
      <Box component={'img'}
           sx={{
             display: 'inline-flex',
             borderRadius: 2,
             border: '1px solid #eaeaea',
             marginBottom: 1,
             height: 100,
             boxSizing: 'border-box'
           }}
           src={environment.cdnUrl + pictureUrl} />
      <div className={'h-20'} />
    </>}
    <ImageUploader onImageUploaded={setPictureUrl} />
    <div className={'h-20'} />
    <Grid container sx={{alignItems: "center", justifyContent: 'space-between'}} direction={'row'}>
      <Grid item>
        <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      </Grid>
      <Grid item>
        <Button disabled={!formik.isValid} variant={'contained'} color={'primary'} type={'submit'}>SAVE</Button>
      </Grid>
    </Grid>
  </form>
}

export const CreateVideoForm = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryId = urlParams.get('categoryId');

  const history = useHistory();
  const [saveVideo] = useSaveVideo();

  const onSubmitForm = React.useCallback((video: Video) => {
    saveVideo({variables: {video: {...video, categoryId: categoryId, sortingOrder: 999, id: undefined}}}).then(() => {
      history.push(paths.videos + '/' + categoryId)
    })
  }, [categoryId]);

  return <Card sx={{p: 2}}>
    <Typography className={'fs-32 medium'}>Create video</Typography>
    <VideoForm onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + categoryId)} />
  </Card>
}

export const UpdateVideoForm = (props: RouteComponentProps<{id: string}>) => {
  const {id} = props.match.params;

  const history = useHistory();
  const [saveVideo] = useSaveVideo();
  const [getVideo, data] = useVideo();

  const initial = React.useMemo(() => {
      if (data?.data?.video) {
        return data?.data?.video;
      }
      return undefined;
  }, [data]);

  const onSubmitForm = React.useCallback((video: Video) => {
    if (initial) {
      saveVideo({variables: {video: {...video, id: initial.id}}}).then(() => {
        history.push(paths.videos + '/' + video.categoryId)
      })
    }
  }, [initial]);

  React.useEffect(() => {
    if (id) {
      getVideo({variables: {videoId: id}})
    }
  }, [id]);

  return initial ? <Card sx={{p: 2}}>
    <Typography className={'fs-32 medium'}>Update video</Typography>
    <VideoForm initial={initial} onSubmit={onSubmitForm} onCancel={() => history.push(paths.videos + '/' + initial.categoryId)} />
  </Card> : null
}
