import React from 'react';
import * as _ from 'lodash';
import { DropResult } from 'smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { useSnackbar } from 'notistack';
import VideoCategories from '../Video/VideoCategories';
import { Preloader } from '@coverright/ui/shared';
import { Box, IconButton, Typography, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useHistory} from "react-router";
import paths from '../router-paths';
import { useDeleteVideoCategory, useSaveCategoryOrder, useVideoCategories } from '@coverright/data-access/medicare';
import { VideoCategoryWithVideos, VideoContentCategoryType } from '@coverright/data-access/types/content-management';

const Podcasts = () => {
  const history = useHistory();
  const {data, refetch, loading} = useVideoCategories();
  const [saveOrder, saveOrderData] = useSaveCategoryOrder();
  const {enqueueSnackbar} = useSnackbar();
  const [rows, setRows] = React.useState<VideoCategoryWithVideos[]>([]);

  const [deleteRow, deleteData] = useDeleteVideoCategory({
    onCompleted: () => refetch(),
  });

  React.useEffect(() => {
    if (data?.videoCategories) {
      setRows(_.sortBy(data?.videoCategories.filter(c => c.type === VideoContentCategoryType.Podcast), 'sortingOrder'))
    }
  }, [data]);

  const delRow = (id: string) => {
    deleteRow({variables: {categoryId: id}})
      .catch(() => enqueueSnackbar('Looks like you have videos in this category, delete or replace them!', {variant: "error"}))
  }

  const onDrop = React.useCallback(({ removedIndex, addedIndex }: DropResult) => {
    const result = arrayMoveImmutable(rows, removedIndex as number, addedIndex as number)
    saveOrder({variables: {ids: result.map((i: any) => i.id)}});
    setRows(result);
  }, [rows]);

  return <Box display={'flex'} flex={1}>
    <Box display={'flex'} flex={1} flexDirection={'column'} position={'relative'}>
      <Preloader in={false} />

      <Card>
        <Box display={'flex'} p={'30px'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Typography className={'fs-32 medium'}>Podcasts</Typography>
          <IconButton component="span" onClick={() => history.push(paths.category + '?type=' + VideoContentCategoryType.Podcast)}>
            <AddIcon />
          </IconButton>
        </Box>
        <VideoCategories rows={rows} onDrop={onDrop} deleteRow={delRow}/>
      </Card>
    </Box>
  </Box>
}

export default Podcasts;
