import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveVideoOrderArgs } from '@coverright/data-access/types/content-management';

export function useSaveVideoOrder(options?: MutationHookOptions<any, MutationSaveVideoOrderArgs>) {
  return useMutation<any, MutationSaveVideoOrderArgs>(gql(saveVideoMutation), {
    ...options,
  })
}

const saveVideoMutation = `
mutation ($ids: [UUID!]!) {
  saveVideoOrder(ids: $ids)
}
`;
