
import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import Layout from './Layout';
import { keycloakCRM, KeycloakProvider } from '@coverright/shared/keycloak';
import { environment } from '../environments/environment';
import { getApolloClient } from '@coverright/data-access/apollo-clients';

export function App() {
  return (
    <KeycloakProvider keycloak={keycloakCRM} onLoad={'login-required'} allowAdmin>
      <ApolloProvider client={getApolloClient(keycloakCRM, environment.graphqlUrl)}>
        <Layout/>
      </ApolloProvider>
    </KeycloakProvider>
  );
}

export default App;

/*
NEW REALM
 <KeycloakProvider keycloak={keycloakCRM} onLoad={'login-required'}>
      <ApolloProvider client={getApolloClient(keycloakCRM)}>
        <Layout/>
      </ApolloProvider>
    </KeycloakProvider>
 */
