import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { QueryVideoArgs, Video } from '@coverright/data-access/types/content-management';


export function useVideo() {
  return useLazyQuery<{video: Video}, QueryVideoArgs>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
    }
  );
}

const request = `
query($videoId: UUID!) {
  video(videoId: $videoId) {
    sortingOrder
    categoryId
    description
    duration
    id
    title
    url
    pictureUrl
  }
}
`;
