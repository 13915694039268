import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A type representing a string identifier */
  UUID: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteUpcomingContent: Scalars['String'];
  deleteVideo: Scalars['String'];
  deleteVideoCategory: Scalars['String'];
  saveCategoryOrder: Scalars['String'];
  saveUpcomingContent?: Maybe<Scalars['UUID']>;
  saveUpcomingContentOrder: Scalars['String'];
  saveVideo?: Maybe<Scalars['UUID']>;
  saveVideoCategory?: Maybe<Scalars['UUID']>;
  saveVideoOrder: Scalars['String'];
};


export type MutationDeleteUpcomingContentArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteVideoArgs = {
  videoId: Scalars['UUID'];
};


export type MutationDeleteVideoCategoryArgs = {
  categoryId: Scalars['UUID'];
};


export type MutationSaveCategoryOrderArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationSaveUpcomingContentArgs = {
  upcomingContent: UpcomingContentDtoInput;
};


export type MutationSaveUpcomingContentOrderArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationSaveVideoArgs = {
  video: VideoInput;
};


export type MutationSaveVideoCategoryArgs = {
  category: VideoCategoryInput;
};


export type MutationSaveVideoOrderArgs = {
  ids: Array<Scalars['UUID']>;
};

export type Query = {
  __typename?: 'Query';
  upcomingContents: Array<UpcomingContentDto>;
  video: Video;
  videoCategories: Array<VideoCategoryWithVideos>;
  videoCategory?: Maybe<VideoCategoryWithVideos>;
  videoContent?: Maybe<VideoCategoryWithVideos>;
};


export type QueryVideoArgs = {
  videoId: Scalars['UUID'];
};


export type QueryVideoCategoryArgs = {
  type: VideoContentCategoryType;
};


export type QueryVideoContentArgs = {
  categoryId: Scalars['UUID'];
};

export type UpcomingContentDto = {
  __typename?: 'UpcomingContentDto';
  buttonLabel?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  isLabelDate: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type UpcomingContentDtoInput = {
  buttonLabel?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  isLabelDate: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  pictureUrl?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleContentAdmin = 'ROLE_CONTENT_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type Video = {
  __typename?: 'Video';
  categoryId?: Maybe<Scalars['UUID']>;
  description: Scalars['String'];
  duration: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  pictureUrl?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type VideoCategoryInput = {
  id?: Maybe<Scalars['UUID']>;
  rowCount?: Maybe<Scalars['Int']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<VideoContentCategoryType>;
};

export type VideoCategoryWithVideos = {
  __typename?: 'VideoCategoryWithVideos';
  id?: Maybe<Scalars['UUID']>;
  rowCount?: Maybe<Scalars['Int']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  subTitle: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<VideoContentCategoryType>;
  upcomingContents: Array<UpcomingContentDto>;
  videos: Array<Video>;
};

export enum VideoContentCategoryType {
  Basics = 'BASICS',
  LiveWebinars = 'LIVE_WEBINARS',
  MedicareFastFacts = 'MEDICARE_FAST_FACTS',
  Podcast = 'PODCAST'
}

export type VideoInput = {
  categoryId?: Maybe<Scalars['UUID']>;
  description: Scalars['String'];
  duration: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  pictureUrl?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  url: Scalars['String'];
};
